<template>
    <page-title
        icon="bi-mortarboard-fill"
        title="学校・イベント登録"
    >
        <button-back
            :to="{name: 'SchoolList'}"
            text="学校・イベント一覧へ"
        ></button-back>
    </page-title>

    <inline-loader v-if="loading"></inline-loader>
    <section v-else class="section">
        <form @submit.prevent="save">
            <div class="form-group col-md-6 mb-3">
                <label>タイプ</label>
                <form-select
                    v-model="school.shooting_type"
                    :options="shooting_types"
                    empty_option="-- 選択 --"
                    :required="true"
                ></form-select>
            </div>
            <div class="row">
                <div class="form-group col-md-6 mb-3">
                    <label v-if="school.shooting_type === ShootingType.EVENT">イベント名</label>
                    <label v-else>学校名</label>

                    <form-input
                        v-model="school.school_name"
                        :required="true"
                    ></form-input>
                </div>
                <div class="form-group col-md-6 mb-3">
                    <label>略称名</label>
                    <form-input
                        v-model="school.shortened_name"
                        :required="true"
                    ></form-input>
                </div>
                <div class="form-group col-md-6 mb-3">
                    <label>校種</label>
                    <form-select
                        v-model="school.school_type"
                        :options="school_types"
                        empty_option="-- 選択 --"
                        :required="true"
                    ></form-select>
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label v-if="school.shooting_type === ShootingType.EVENT">イベント担当者</label>
                <label v-else>学校担当者</label>

                <form-select
                    v-model="school.employee.photographer_id"
                    :options="options_school_photographer"
                    empty_option="-- 選択 --"
                    :required="true"
                ></form-select>
            </div>
            <div class="form-group mb-3">
                <div class="row">
                    <div class="col-4">
                        <label>都道府県</label>
                        <form-select
                            v-model="school.pref"
                            :options="prefs"
                            empty_option="-- 選択 --"
                        ></form-select>
                    </div>
                    <div class="col-8">
                        <label>住所</label>
                        <form-input
                            v-model="school.address"
                        ></form-input>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>電話番号</label>
                <form-input-tel
                    v-model="school.tel"
                ></form-input-tel>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>先方窓口</label>
                <form-input
                    v-model="school.contact_person"
                ></form-input>
            </div>
            <div class="form-group col-md-10 mb-5">
                <label>注意事項</label>
                <form-textarea
                    v-model="school.note"
                    row=3
                ></form-textarea>
            </div>
            <div>
                <button-exec-create
                    size="btn-lg"
                ></button-exec-create>
            </div>
        </form>

    </section>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import Pref from '@/models/enums/pref';
import SchoolType from '@/models/enums/school/school-type';
import ShootingType from '@/models/enums/school/shooting-type';
import FormSelect from '@/components/forms/FormSelect.vue';
import School from '@/models/entities/school';
import ButtonBack from '@/components/buttons/ButtonBack';
import InlineLoader from '@/components/tools/InlineLoader';
import FormInput from '@/components/forms/FormInput';
import FormTextarea from '@/components/forms/FormTextarea';
import FormInputTel from '@/components/forms/FormInputTel';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate';

export default {
    name: 'SchoolAdd',
    components: {
        PageTitle,
        FormSelect,
        ButtonBack,
        InlineLoader,
        FormInput,
        FormInputTel,
        FormTextarea,
        ButtonExecCreate
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: 0,
            school: new School(),

            // 都道府県
            prefs: Pref.options(),
            // タイプ
            shooting_types: ShootingType.options(),
            // 校種
            school_types: SchoolType.options(),
            // 学校担当者
            options_school_photographer: [],

            ShootingType: ShootingType,
        }
    },
    mounted() {
        this.FetchSchoolPhotographerList();
    },
    methods: {
        FetchSchoolPhotographerList() {
            this.loading++;
            this.$http.get('/employees/all')
            .then(response => {
                for (let row of response.data) {
                    this.options_school_photographer.push({key: row.photographer_id, label: row.photographer_name})
                }
            })
            .finally(() => {
                this.loading--;
            });
        },

        save() {
            this.startScreenLoading();

            this.$http.post('/schools', this.school)
            .then(() => {
                this.showMessage('登録しました');
                this.$router.push({name: 'SchoolList'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
